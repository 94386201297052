




































import {Component, Prop, Vue} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import CreateCCDRPayload from "@/dto/request/сcdr/CreateCCDRPayload";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CCDRService from "@/services/request/CCDRService";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component({
  components: {PortalInput, PortalTextarea}
})
export default class CreateCCDR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop({default: null})
  company!: CompanyDTO | null;

  @Prop()
  onCreated!: (() => number) | null;

  payload = new CreateCCDRPayload();

  create() {
    this.$validator.validate().then(
        isOk => {
          if (isOk) {
            this.payload.companyId = this.company?.id;
            this.startLoading();
            CCDRService.createRequest(this.payload).then(
                ok => {
                  this.stopLoading();
                  if (this.onCreated) {
                    this.onCreated();
                  }
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.stopLoading();
                }
            )
          }
        }
    )
  }

}
