import axios from 'axios';
import {REQUESTS_CCDR_ENDPOINT, REQUESTS_CCDR_PUBLIC_ENDPOINT, REQUESTS_RCCR_PUBLIC_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateCCDRPayload from "@/dto/request/сcdr/CreateCCDRPayload";
import CCDR_DTO from "@/dto/request/сcdr/CCDR_DTO";

class CCDRService {

    createRequest(payload: CreateCCDRPayload) {
        return axios.post<number>(REQUESTS_CCDR_ENDPOINT, payload, {headers: authHeader()});
    }

    getPublic(id: string) {
        return axios.get<CCDR_DTO>(`${REQUESTS_CCDR_PUBLIC_ENDPOINT}/${id}`);
    }

    uploadCCDRDocument(publicId: string, fileMetaId: number) {
        return axios.post<void>(`${REQUESTS_CCDR_PUBLIC_ENDPOINT}/${publicId}/documents`, {fileMetaId: fileMetaId});
    }



}

export default new CCDRService();