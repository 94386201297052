


































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {RCCRDocumentDTO, RegisterCompanyCustomerRequestDTO} from "@/dto/request/rccr/RegisterCompanyCustomerRequestDTO";
import RCCRService from "@/services/request/RCCRService";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {RCCRDocumentType, RCCRRequestStatus} from "@/constants/request/RequestsStatuses";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import RCCRCreateCompanyPayloadDTO from "@/dto/request/rccr/RCCRCreateCompanyPayloadDTO";
import NAICS_DICT from "@/data/NaicsDict.json";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import RouteNames from "@/router/RouteNames";
import CompanyService from "@/services/CompanyService";
import CompanyDTO from "@/dto/company/CompanyDTO";
import EmploymentDTO from "@/dto/employee/EmploymentDTO";
import AddressDTO from "@/dto/AddressDTO";
import CreateCCDR from "@/components/request/ccdr/CreateCCDR.vue";


const AppModule = namespace("App");

@Component({
  computed: {
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {AutoComplete, SingleFileHolder}
})
export default class RCCR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: RegisterCompanyCustomerRequestDTO | null = null;

  DOCUMENT_TYPES = Object.values(RCCRDocumentType);
  naics = NAICS_DICT;

  createCompanyMode = false;

  createCompanyPayload = new RCCRCreateCompanyPayloadDTO();

  company: CompanyDTO | null = null;

  mounted() {
    this.loadRequest().then(() => this.loadCompany())
  }

  loadRequest() {
    this.startLoading();
    return RCCRService.getRCCR(this.id).then(
        response => {
          this.request = response.data;
          this.request?.documents.sort((a, b) => a.id - b.id)
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    );
  }

  loadCompany() {
    if (!this.isCreated && !this.areDocumentsUploaded) {
      this.startLoading();
      return CompanyService.getById(this.request?.request.company?.id as number).then(
          ok => {
            this.company = ok.data;
            this.stopLoading();
          },
          error => {
            this.stopLoading();
            console.log(JSON.stringify(error));
          }
      )
    } else {
      return Promise.resolve();
    }
  }

  get id() {
    return Number.parseInt(this.$route.params.id);
  }

  changeDocumentStatus(doc: RCCRDocumentDTO) {
    this.startLoading();
    RCCRService.updateRCCRDocument(doc).then(
        ok => {
          this.stopLoading();
          this.loadRequest();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    );
  }

  public maskHandle() {
    let mask = "__-_______";
    let oldVal = this.createCompanyPayload.companyEin!.replace(/\D/g, "");

    if (mask.length >= this.createCompanyPayload.companyEin!.length) {
      this.createCompanyPayload.companyEin = this.buildPhoneWithMask(mask, oldVal);
    } else if (mask.length < this.createCompanyPayload.companyEin!.length) {
      this.createCompanyPayload.companyEin = this.createCompanyPayload.companyEin!.substr(0, mask.length)
    }
  }

  public ssnMaskHandle() {
    let mask = "___-__-____";
    let oldVal = this.createCompanyPayload!.officerSsn!.replace(/\D/g, "");

    if (mask.length >= this.createCompanyPayload!.officerSsn!.length) {
      this.createCompanyPayload!.officerSsn = this.buildPhoneWithMask(mask, oldVal);
      if (this.createCompanyPayload!.officerSsn!.substr(oldVal.length - 1) == "-") {
        this.createCompanyPayload!.officerSsn = oldVal.substr(0, oldVal.length);
      }
    } else if (mask.length < this.createCompanyPayload!.officerSsn!.length) {
      this.createCompanyPayload!.officerSsn = this.createCompanyPayload!.officerSsn!.substr(0, mask.length)
    }
  }

  private buildPhoneWithMask(mask: string, oldVal: string) {
    let i = 0;

    let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a: string) {
      return i < oldVal.length ? oldVal.charAt(i++) : a
    }));

    if (newValue.length <= mask.length) {
      oldVal = newValue;
    }

    if (this.createCompanyPayload.companyEin!.substr(oldVal.length - 1) == "-") {
      newValue = oldVal.substr(0, oldVal.length);
    }

    return newValue;
  }

  private deleteSuffixIfNeed(newValue: string) {
    if (newValue.indexOf("_") != -1) {
      newValue = newValue.substr(0, newValue.indexOf("_"))
    }

    if (newValue.substr(newValue.length - 1) == "-") {
      newValue = newValue.substr(0, newValue.length - 1);
    }

    let suffix = newValue.substr(newValue.length - 2);

    if (suffix == " (" || suffix == ") ") {
      newValue = newValue.substr(0, newValue.length - 2)
    }

    return newValue;

  }

  createCompany() {
    this.startLoading();
    RCCRService.completeRCCR(this.id, this.createCompanyPayload).then(
        ok => {
          this.stopLoading();
          this.loadRequest().then(
              ok => {
                this.goToCompany();
              }
          );
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    )
  }

  showUploadNewDocumentModal() {
    this.$modal.show(
        FileUpload,
        {
          multiple: true,
          onUploaded: (files: FileMetaDTO[]) => this.onNewFilesUploaded(files)
        }
    )
  }

  onNewFilesUploaded(files: FileMetaDTO[]) {
    this.startLoading();
    RCCRService.uploadRCCRDocument(this.request!.publicId, files[0].id).then(
        response => {
          this.$modal.hideAll();
          this.loadRequest();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  get getCustomerName(): string {
    return this.request?.request.company?.name || this.request?.request.customerNamePlaceholder || "";
  }

  get isCreated(): boolean {
    return this.request?.request.status == RCCRRequestStatus.CREATED;
  }

  get areDocumentsUploaded(): boolean {
    return this.request?.request.status == RCCRRequestStatus.DOCUMENTS_UPLOADED;
  }

  get isCallScheduled(): boolean {
    return this.request?.request.status == RCCRRequestStatus.CALL_SCHEDULED;
  }

  get isIsWaitingForLinkedRequests(): boolean {
    return this.request?.request.status == RCCRRequestStatus.WAITING_FOR_LINKED_REQUESTS;
  }

  get isCompleted(): boolean {
    return this.request?.request.status == RCCRRequestStatus.COMPLETED;
  }

  goToCompany() {
    this.$router.push({name: RouteNames.PROFILE_COMPANY_DETAILS, params: {"wsType": `${WorkspaceTypePathElement.COMPANY}`, "wsId": `${this.request?.request.company?.id}`}});
  }

  buildFullName(employment: EmploymentDTO) {
    return `${employment.employee.firstName} ${employment.employee.lastName}`;
  }

  buildAddress(address: AddressDTO) {
    return address ? `${address.address1} ${address.address2} ${address.city} ${address.state} ${address.zipCode}` : '';
  }

  requestDocuments() {
    this.$modal.show(
        CreateCCDR,
        {
          company: this.company,
          onCreated: () => this.$modal.hideAll()
        }
    )
  }

}
