import axios from 'axios';
import {CreateRequestAPIRequestDTO} from "@/dto/request/CreateRequestAPIRequestDTO";
import {REQUESTS_RCCR_DOCUMENTS_ENDPOINT, REQUESTS_RCCR_ENDPOINT, REQUESTS_RCCR_PUBLIC_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import {RCCRDocumentDTO, RegisterCompanyCustomerRequestDTO} from "@/dto/request/rccr/RegisterCompanyCustomerRequestDTO";
import RCCRCreateCompanyPayloadDTO from "@/dto/request/rccr/RCCRCreateCompanyPayloadDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";

class RCCRService {

    createRequest(apiRequest: CreateRequestAPIRequestDTO) {
        return axios.post(REQUESTS_RCCR_ENDPOINT, apiRequest, {headers: authHeader()});
    }

    getRCCR(id: number) {
        return axios.get<RegisterCompanyCustomerRequestDTO>(`${REQUESTS_RCCR_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updateRCCRDocument(doc: RCCRDocumentDTO) {
        return axios.put(REQUESTS_RCCR_DOCUMENTS_ENDPOINT, doc, {headers: authHeader()});
    }

    getPublicRCCR(id: string) {
        return axios.get<RegisterCompanyCustomerRequestDTO>(`${REQUESTS_RCCR_PUBLIC_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    uploadRCCRDocument(publicId: string, fileMetaId: number) {
        return axios.post<void>(`${REQUESTS_RCCR_PUBLIC_ENDPOINT}/${publicId}/documents`, {fileMetaId: fileMetaId},);
    }

    completeRCCR(id: number, payload: RCCRCreateCompanyPayloadDTO) {
        return axios.post<CompanyDTO>(`${REQUESTS_RCCR_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

}

export default new RCCRService();